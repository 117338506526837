import {
  ref, computed, watch, set,
} from '@vue/composition-api'
import store from '@/store'

export default function useBasicTable(props) {
  const refBasicTable = ref(null)
  const gridData = ref(null)
  const loading = ref(false)
  const total = ref(0)
  const currentPage = ref(1)
  const perPage = ref(25)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = gridData.value ? gridData.value.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const fetchData = () => {
    loading.value = true
    // eslint-disable-next-line prefer-destructuring
    const xparam1 = props.xparam1
    // eslint-disable-next-line prefer-destructuring
    const xparam2 = props.xparam2
    // eslint-disable-next-line prefer-destructuring
    const xparam3 = props.xparam3
    // eslint-disable-next-line prefer-destructuring
    const xparam4 = props.xparam4
    // eslint-disable-next-line
    const id_buyer = props.id_buyer
    return store.dispatch('basic-table/fetchData', {
      api: props.api,
      apiId: props.apiId,
      params: {
        xparam1,
        xparam2,
        xparam3,
        xparam4,
        // eslint-disable-next-line
        id_buyer,
        sortby: sortBy.value,
        order: isSortDirDesc.value ? 'desc' : 'asc',
        zpage: currentPage.value,
        zstart: perPage.value * (currentPage.value - 1),
        zlimit: perPage.value,
      },
    })
      .then(response => {
        const { data } = response.data
        gridData.value = data.rows
        total.value = data.paging[0].total || 0
        loading.value = false
        return response
      })
      .catch(err => {
        gridData.value = null
        total.value = 0
        loading.value = false
        return err
      })
  }

  const rowClicked = item => {
    // eslint-disable-next-line no-underscore-dangle
    if (!item._showDetails) {
      // eslint-disable-next-line no-underscore-dangle
      set(item, '_showDetails', !item._showDetails)
    } else {
      // eslint-disable-next-line no-underscore-dangle
      set(item, '_showDetails', !item._showDetails)
    }
  }

  const execAction = action => {
    store.dispatch('basic-table/execAction', {
      ...action,
      alert: action.alert === undefined || false,
    }).then(res => {
      if (res) {
        fetchData()
      }
    })
  }

  watch(() => props.apiId, () => {
    if (props.loadByTab === null || (props.currentTab === props.loadByTab)) {
      fetchData()
    }
  })

  watch(() => props.xparam1, () => {
    fetchData()
  })

  watch(() => props.xparam2, () => {
    fetchData()
  })

  watch(() => props.xparam3, () => {
    fetchData()
  })

  // eslint-disable-next-line
  watch(() => props.id_buyer, () => {
    if (props.loadByTab === null || (props.currentTab === props.loadByTab)) {
      fetchData()
    }
  })

  watch(() => props.currentTab, () => {
    if (props.currentTab === props.loadByTab) {
      fetchData()
    }
  })

  watch([currentPage, sortBy, isSortDirDesc], () => {
    fetchData()
  })

  watch(() => props.refresh, () => {
    if (props.refresh) {
      fetchData()
    }
  })

  return {
    refBasicTable,
    gridData,
    loading,
    total,
    currentPage,
    perPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    dataMeta,

    fetchData,
    rowClicked,
    execAction,
  }
}
